@value app: '../app.css';
@value mobile-breakpoint from app;

.container {
  flex: 1;
  background-color: #f9fafd;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.content {
  padding-left: 32px;
  padding-right: 32px;
}

.loading {
  margin-top: 100px;
  display: grid;
  place-items: center;
  flex: 1;
  height: 100%;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* Set height so we can caluclate the height of the feed container*/
  height: 78px;
}

.subtitle {
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 12px;
  /* Set height so we can caluclate the height of the feed container*/
  height: 14px;
}

.bodyContainer {
  padding-top: 12px;
  display: flex;
}

.proposalFeedContainer {
  flex: 1;
  max-height: 1200px;
  overflow: scroll;
  margin-right: 8px;
  padding-bottom: 12px;
  min-height: 500px;
  padding-right: 10px;
}

.proposalFeedContainer::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.proposalFeedContainer::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 10px;
}

/* Handle on hover */
.proposalFeedContainer::-webkit-scrollbar-thumb:hover {
  background: #d6d1d1;
}

.proposalDetailsContainer {
  flex: 2;
  margin-bottom: 22px;
  max-width: 65%;
}

.sectionTitle {
  font-family: "cairo";
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 12px;
}

.metadataContainer {
  flex: 1;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 26px;
  overflow-x: scroll;
}

.metadata {
  background-color: white;
  height: 100%;
  border-radius: 5px;
  padding: 2em 2em 2em 2em;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.thumbnail {
  flex: 1;
  position: relative;
  display: flex;
  height: 250px;
  margin-bottom: 8px;
}

.image {
  border-radius: 10px;
}

.metadata::-webkit-scrollbar {
  height: 8px;
}

/* Handle */
.metadata::-webkit-scrollbar-thumb {
  background: #d4d3d3;
  border-radius: 10px;
}
/* Handle on hover */
.metadata::-webkit-scrollbar-thumb:hover {
  background: #d6d1d1;
}

.logout {
  position: absolute;
  top: 33px;
  right: 0;
}

.noProposalView {
  display: grid;
  place-items: center;
  flex: 1;
}

.noProposalText {
  margin-bottom: 12px;
}

@media (max-width: mobile-breakpoint) {
  .content {
    padding-left: 18px;
    padding-right: 18px;
  }

  .proposalDetailsContainer {
    display: none;
  }

  .proposalFeedContainer {
    margin-right: 0px;
    padding-right: 0px;
    max-height: none;
  }
}
