.proposalCard {
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 18px;
  margin-right: 0px;
  padding: 14px 16px 14px 16px;
  border-radius: 7px;
  background-color: #f4f4f4;
  overflow: hidden;
  transform: scale(1);
  transition: transform 200ms;
  width: 100%;
  display: flex;
  gap: 16px;
}

.proposalCard:active {
  transform: scale(0.98);
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.initials {
  padding: 2px 8px;
  background-color: #dce1ff;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 13px;
}

.warningContainer {
  margin-right: 8px;
  display: flex;
  align-self: center;
  margin-bottom: 2px; /* Needed due to triangle icon viewbox white space at top*/
}

.marginRightAuto {
  margin-right: auto;
}

.bottomMargin {
  margin-bottom: 4px;
}

.isActive {
  border-left: solid 4px #0d6efd;
  background-color: white;
}

.outlined {
  background-color: white;
  border: 1px solid #000000;
}

.imageContainer {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}

.proposalImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholderImage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.contentContainer {
  flex: 1;
}
